/********************************************************************************* CUSTOM STYLES */

/*  index

    ////// MIXINS & VARIABLES
    ////// GRID
    ////// COMMONS
    ////// ERROR PAGES
    ////// SECTIONS > USERS
    ////// SECTIONS > USER DETAIL
    ////// SECTIONS > USER F1
    ////// SECTIONS > CONFIGURABLES

*/


/*
    ////// MIXINS & VARIABLES
*/


@mixin bp($point) {
  
    $bp-mobile: "(max-width: 600px)";
    $bp-tablet: "(max-width: 768px)";
    $bp-desktop: "(max-width: 1200px)";
    $bp-superdesktop: "(min-width: 1201px)";

    @if $point == superdesktop {
        @media #{$bp-superdesktop} { @content; }
    }
    @else if $point == desktop {
        @media #{$bp-desktop} { @content; }
    }
    @else if $point == tablet {
        @media #{$bp-tablet}  { @content; }
    }
    @else if $point == mobile {
        @media #{$bp-mobile}  { @content; }
    }
}

@mixin flex-container{
    width: 100%;

    display: -webkit-box; /*iOS6, Safari 3.1-6*/

    display: -ms-flexbox; /*IE10*/
    -ms-flex-direction: row; /*IE10*/
    -ms-flex-wrap: wrap; /*IE10*/ 
    -ms-align-items: stretch;
    -ms-align-content: stretch;
    -ms-justify-content: space-between;

    display: -webkit-flex; /*android 4.3, IE mobile, Safari*/
    -webkit-flex-direction: row;  /*iOS6, Safari 3.1-6*/
    -webkit-flex-wrap: wrap;/*iOS6, Safari 3.1-6*/
    -webkit-align-items: stretch;
    -webkit-align-content: stretch;
    -webkit-justify-content: space-between;

    display: flex;	             
    flex-direction: row;		                  
    flex-wrap: wrap;
    align-items:stretch;
    align-content: stretch;
    justify-content: space-between;

    height: 100%;
}
@mixin flex-flexible-element{
    -webkit-box-flex: 1 0; /*iOS6, Safari 3.1-6*/
    -ms-flex: 1 0; /*IE10*/
    -webkit-flex: 1 0;/*android 4.3, IE mobile, Safari*/
    flex: 1 0;
    min-width: 0;
}
@mixin flex-fixed-element($width){
    -webkit-box-flex: 0 0 $width; /*iOS6, Safari 3.1-6*/
    -ms-flex: 0 0 $width; /*IE10*/
    -webkit-flex: 0 0 $width;/*android 4.3, IE mobile, Safari*/
    flex: 0 0 $width;
    min-width: 0;
}

@mixin color-link($color) {
    color: $color;

    &:link, &:visited, &:active{
        color: $color;
    }
}

/*colors*/
$color-main:        #111111;
$color-secondary:   #AE003A;
$color-red:         #ED4337;
$color-beige:       #F6F4F0;
$color-light-gray:  #d2d6de;
$color-soft-blue:   #ecf0f5;
$color-blue:        #3c8dbc;




@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}


/*
    ////// GRID
*/
.row{
    & > .col-xs-6, & > .col-xs-4, & > .col-xs-3{
        &:only-child{
            width: 100%;
    
            .custom_form{
                @include flex-container;
                
                .form-group, .nav-tabs-custom{
                    @include flex-fixed-element(48%);
                    
                    @include bp(desktop){
                        @include flex-fixed-element(100%);
                    }
                }
            }
        }
    }
    .col-xs-6, & > .col-xs-4, & > .col-xs-3, & > .col-md-5, & > .col-md-2, & > .col-md-4{
        @include bp(desktop){
            width: 100%;        
        }
    }
}

@supports (display: flex) {
    .row {
        width: 100%;
        display: -webkit-box; /*iOS6, Safari 3.1-6*/
        display: -ms-flexbox; /*IE10*/
        display: -webkit-flex; /*android 4.3, IE mobile, Safari*/
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items:stretch;
        align-content: stretch;
        justify-content: space-between;

        height: 100%;
        margin: 0 0 20px 0;

        .row{
            margin: 0;
            height: auto;
        }
    }

    .col-xs-6, .col-sm-6{
        flex: 0 0 50%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-xs-4{
        flex: 0 0 33%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-xs-12, .col-sm-12{
        flex: 0 0 100%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-md-2{
        flex: 0 0 16%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-xs-3{
        flex: 0 0 25%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-sm-5{
        flex: 0 0 41%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .col-sm-7{
        flex: 0 0 58%;
        min-width: 0;

        @include bp(desktop){
            flex: 0 0 100%;
            margin: 0 0 20px 0;
        }
    }
    .box{
        height: 100%;
    }
    .box-body {
        @include bp(desktop){
            .col-xs-6, .col-sm-6, .col-xs-4, .col-xs-12, .col-sm-12, .col-md-2, .col-xs-3, .col-sm-5, .col-sm-7{
                margin: 0;
            }
        }
    }
}


/*
    ////// COMMONS
*/
.sidebar-menu{
    li{
        &.active{
            &>a{
                color: #4CA8D0!important;
                border-left-color:#4CA8D0!important;
            }
            li{
                &.active{
            &>a{
                    color: #4CA8D0!important;
                }
            }
            }
        }
    }
}
.radio {
    display:inline;
    margin-right: 10px;
}

.radio-options {
    margin: 10px;
}
.return-to-top {

    &:hover {
        background: rgba(60, 141, 188, 1);
    }

    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(60, 141, 188);
    background: rgba(60, 141, 188, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    display: none;
    transition: all 0.3s ease;
    z-index: 10000;

    i {
        color: #fff;
        margin: 0;
        position: relative;
        left: 16px;
        top: 13px;
        font-size: 19px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &:hover {
            color: #fff;
            top: 5px;
        }
    }
}
.box-body{
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
        background: none;
        border-color: transparent;
    }
}
.style-select {
    background: url("../img/custom/arrow-down.svg") no-repeat right 10px center white;
    background: none\0;
    width: 100%;
    border: 1px solid $color-light-gray;
    border: none\0;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    /*-moz-appearance:window;*/
    //padding-right: 40px;
    padding-right: 0;
    padding-left: 10px;
    min-width: 100px;

    select {
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*-moz-appearance:window;*/
        width: 100%;
        height: 100%;
        padding: 6px 12px;
        border: none;
        border-radius: 100px;

        &:hover{
            cursor: pointer;
        }

        &:disabled{
            background: white;
        }
    }
    .select2-container--default .select2-selection--single{
        border: none;
        background: transparent;

        .select2-selection__arrow{
            opacity: 0;
        }
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .style-select {
        background: none;
        border: none;

        select {
            background: white;
        }
    }
}
.form-group{
    .select2{
        background: url("../img/custom/arrow-down.svg") no-repeat right 10px center white;
        border: 1px solid $color-light-gray;
        border-radius: 4px;


        .select2-selection__arrow{
            opacity: 0;
        }
    }
    .select2-container--default .select2-selection--single, .select2-selection .select2-selection--single{
        background: none!important;
        border: none!important;
        padding: 6px 12px;
        height: 32px;
    }
    .style-select .select2{
        background: none;
        border: none;
    }
    &.has-error{
        .style-select{
            border: 1px solid #dd4b39;
        }
    }
}

.table-content{
    max-width: 99%;
    min-width: 300px;
    overflow-x: auto;
    position: relative;

    /*padding-bottom: 30px;
    position: relative;

    &:hover{
        &:after{
            content: 'scroll';
            position: absolute;
            bottom: 20px;
        }
    }*/
}
.navbar-right{
    margin: 0 10px 0 0;
    padding-top: 10px;
}
.page-index{
    background: darken($color-soft-blue,5%);
    padding: 10px 10px 10px 60px;
    position: relative;

    &:before{
        content: 'INDEX';
        position: absolute;
        transform: rotate(-90deg);
        font-size: 50px;
        left: -35px;
        top: 45px;
        font-weight: bold;
        color: darken($color-soft-blue,25%);
    }

    ul{
        margin: 0;
        padding: 0;
    }
    li{
        list-style-type: none;
        border-bottom: 1px dotted darken($color-soft-blue,20%);

        &:last-child{
            border: none;
        }

        a{
            padding: 2px 10px;
            display: block;
            @include color-link($color-main);

            &:hover{
                color: $color-blue;
                cursor: pointer;
            }
        }
        ul{
            margin: 0 0 0 30px;
        }
    }
}

@include bp(tablet){
    div.dataTables_wrapper > div.row > div, div.dataTables_length, div.dataTables_filter, div.dataTables_info, div.dataTables_paginate{
        text-align: left!important;
    }
}

/*
    ////// ERROR PAGES
*/
.page-error{
    .content-wrapper{
        text-align: center;
        padding: 80px 10px;
        color: $color-red;
    }
}

/*
    ////// SECTIONS > USERS
*/
.user-filters{
    background: url("../img/custom/icon-search.svg") no-repeat 20px center $color-beige;
    padding: 20px 20px 20px 90px;

    h2{
        font-size: 20px;
        margin: 0 0 10px 0;
    }

    @include bp(desktop){
        background: $color-beige;
        padding: 20px 10px;
    }
}
.users_list {
    .user-filters {
        .form-control {
            border: 1px solid $color-light-gray;
        }
        .style-select{
            .form-control{
                border: none;
            }
        }
    }
    .user-referent-label{
        margin: 0 0 0 5px;
    }
}
#users-list{

    tr{
        th, td{
            &:last-child{
                text-align: right;
                width: 100px!important;
            }
        }
    }
    .alert-row{
        background: lighten($color-red,5%);
        color: white;

        a{
            @include color-link(white);
        }

        td:nth-child(1){
            padding: 5px 5px 5px 40px;
            background: url("../img/custom/icon-alert.svg") no-repeat 10px 8px;
        }
    }
}
.button_re, .button_rs, .button_f1, .button_f2, .btn-warning{
    margin: 0 0 0 3px;

    i{
        margin: 0 3px 0 0;
    }
}
.form-group{
    input{
        border-radius: 4px;
    }
}
.table{
    tr{
        &.odd, &.even{
            &:hover{
                background: darken($color-beige,3%);
            }
        }
    }
}
.user-list_name:hover {
    font-weight: bold;
    text-decoration: underline;
}


/*
    ////// SECTIONS > USER DETAIL
*/
.add-new-phone{
    cursor: pointer;
}
.user-phone-block{
    label{
        vertical-align: top;
    }
}
.remove-phone{
    @include color-link($color-red);
    margin: 10px 0;
    //display: block;

    @include bp(tablet){
        display: block;
    }
}
.user-phone-item{
    margin: 0 0 10px 0;

    .form-group{
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 0;

        &:last-child{
            margin: 0;
        }
        @include bp(tablet){
            display: block;
            margin: 0 0 5px 0;
        }
    }
}


/*
    ////// SECTIONS > USER F1
*/
.back-top{
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 1000;
    background: $color-secondary;
    padding: 10px 20px;
    display: block;
    @include color-link(white);

    &:hover{
        color: white;
    }
}
.f1-page, .f2-page{
    h2{
        font-size: 24px;
    }
    h3{
        font-size: 20px;
        font-weight: bold;
    }
}
.pagen-anchor-target{
    &:focus{
        animation: pulse;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;

        border: none!important;
        color: $color-secondary;
    }
}
.user-related-sheets{
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
}
.management_situation_others, .state_situation_others, .judicial_sentence_date_wrapper, .loan_entity_others, .nationality_others, .country_others{
    padding-left: 30px;
    position: relative;

    &:before{
        content: '';
        position: absolute;
        border-left: 1px dotted darken($color-light-gray,30%);
        width: 20px;
        height: 58px;
        margin-top: -15px;
        left: 10px;
        border-bottom: 1px dotted darken($color-light-gray,30%);
    }

}
.diary-entries{
    margin: 30px 0 0 0;
    border-top: 1px dashed $color-secondary;
}
#diary-entry-list{
    th{
        &:first-child{
            min-width: 150px;
        }
    }
}
.add-new-family-member, .add-new-family-member-earning{
    float: right;
}

.family-member-data-wrapper{
    h3{
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px dashed $color-main;
        padding-bottom: 10px;
    }
}
.family_member_tool_btn{
    float: right;
    margin: 0 5px 0 0;
}
.total-family-earnings{
    background: $color-beige;
    padding: 10px;
    margin: 5px 0;

    p{
        margin: 0;
        display: inline-block;
        vertical-align: top;
    }
    .family_member_tool_btn{
        display: inline-block;
        vertical-align: top;
        float: right;
    }
}
.family-person-earning{
    margin: 0 0 10px 0;
}
.family-members-group, .family-person-earning, .help-types-group{
    .form-group{
        display: inline-block;
        vertical-align: top;
        min-width: 200px;
        margin: 0;

        @include bp(tablet){
            min-width: 1px;
            max-width: 175px;
        }
    }
    .btn{
        display: inline-block;
        vertical-align: top;
        margin-top: 25px;
    }
    .select2{
        display: block;
        min-width: 200px;
    }
}
.family-member-earnings-wrapper{
    margin: 0 0 10px 0;
}
.family-person{
    background: lighten($color-light-gray,10%);
    padding: 20px 10px;
    margin: 0 0 30px 0;
}
.block-actions{
    display: block;
    margin: 10px 0;
}
.coexistence-units-group{
    .col-md-5{
        width: 38%;
    }
}

/*
    ////// SECTIONS > CONFIGURABLES
*/
.config-elements-list{
    margin: 0;
    padding: 0;

    li{
        list-style-type: none;
        margin: 0;
        border-bottom: 1px solid $color-beige;

        a{
            display: block;
            padding: 7px 5px;
        }

        &:hover{
            a{
                background: darken($color-beige,3%);
            }
        }
        &:last-child{
            border: none;
        }
    }
}
